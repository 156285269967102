<template>
  <div>
    <TermsTemplate1 :title1="title1" :title2="title2" />
  </div>
</template>

<script>
import TermsTemplate1 from "../components/extras/TermsTemplate1.vue";
import { mapActions } from "vuex";
export default {
  components: {
    TermsTemplate1,
  },

  data() {
    return {
      title1: "Relsify Privacy Policy",
      title2: "Global Privacy Policy",
    };
  },

  methods: {
    ...mapActions(["setCurrentPageName"]),
  },

  mounted() {
    this.setCurrentPageName(this.$router.currentRoute._rawValue.name);
  },
};
</script>
