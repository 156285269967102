<template>
  <div class="productQuote">
    <div class="productQuoteContent">
      <span class="quote_tag oneT">“</span>
      <span ref="product_quote_text" class="quote_text product_quote_text">
        "90% of all millionaires become so through owning real estate. More
        money has been made in real estate than in all industrial investments
        combined. The wise young man or wage earner of today invests his money
        in real estate" - Andrew Carnegie
      </span>
      <span class="quote_tag twoT">“</span>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  setup() {
    const product_quote_text = ref(null)

    const handle_intersection = ([entry], observer) => {
      if (entry.isIntersecting) {
        if (entry.target.classList.contains('product_quote_text')) {
          entry.target.classList.add('zoom-in')
          observer.unobserve(entry.target)
        }
      }
    }

    const product_quote_text_observer = new IntersectionObserver(handle_intersection)

    return {
      product_quote_text,

      product_quote_text_observer
    }
  },
  mounted() {
    this.product_quote_text_observer.observe(this.product_quote_text)
  }
};
</script>

<style scoped>
.productQuote {
  width: 100%;
  display: block;
  background: #fff;
  margin-bottom: -100px;
  overflow: hidden;
}

.productQuoteContent {
  padding: 40px 60px 0px;
  width: 810px;
  margin: 0px auto;
}

.quote_tag {
  font-style: italic;
  font-weight: 500;
  font-size: 129px;
  display: block;
  width: 100%;
  text-align: left;
  color: #e7e7e7;
}

.quote_tag.twoT {
  /* font-family: Inter; */
  text-align: right;
  width: 100%;
  display: block;
  margin-top: -45px;
}

.quote_text {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 38px;
  text-align: center;
  color: #515151;
  display: inline-block;
  margin-top: -80px;
  margin-left: 40px;
}

@media screen and (max-width: 1350px) {
  /* .productQuoteContent {
    padding: 40px 20px 40px 90px;
    width: 710px;
  } */
}

@media screen and (max-width: 1250px) {
  /* .productQuoteContent {
    padding: 40px 20px 40px 90px;
    width: 710px;
  } */

  .quote_tag {
    font-size: 100px;
  }

  .quote_tag.twoT {
    margin-top: -70px;
  }

  .quote_text {
    font-size: 19px;
    line-height: 38px;
    margin-top: -60px;
    margin-left: 20px;
  }
}

@media screen and (max-width: 1070px) {
  /* .productQuoteContent {
    padding: 40px 50px 40px 40px;
    width: auto;
  } */

  .quote_text {
    font-size: 18px;
    line-height: 28px;
    margin-top: -60px;
    margin-left: 20px;
  }

  .quote_tag {
    font-size: 70px;
  }

  .quote_tag.twoT {
    margin-top: -50px;
  }
}

@media screen and (max-width: 870px) {
  /* .productQuote {
    grid-template-columns: 220px 1fr;
  } */

  .productQuote {
    margin-bottom: -40px;
  }

  .productQuoteContent {
    padding: 40px 40px 0px;
    width: 95%;
    margin: 0px auto;
  }
}

@media screen and (max-width: 768px) {
  .quote_text {
    margin-left: 10px;
  }
}

@media screen and (max-width: 650px) {
  .productQuoteContent {
    padding: 40px 20px 40px;
    width: 90%;
  }

  .quote_text {
    font-size: 16px;
    margin-top: -30px;
  }
  .quote_tag {
    font-size: 60px;
  }
}

@media screen and (max-width: 450px) {
  /* .productQuote img {
    height: 420px !important;
    object-fit: cover;
  } */

  .productQuoteContent {
    padding: 40px 12px 40px;
    width: 95%;
  }

  .quote_text {
    margin-left: 0px;
  }

  /* .quote_tag.twoT {
    margin-right: 30px;
  } */
}
</style>
