<template>
  <div class="marketplace-banner">
    <div class="marketplace-banner-content">
      <h4>
        Find Your Next <span class="text-green">Investment Opportunity</span>
      </h4>
    </div>
    <div ref="marketplace_banner_image" class="marketplace-banner-img marketplace_banner_image">
      <img src="@/assets/img/building.png" alt="" />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  setup() {
    const marketplace_banner_image = ref(null)

    const handle_intersection = ([entry], observer) => {
      if (entry.isIntersecting) {
        if (entry.target.classList.contains('marketplace_banner_image')) {
          entry.target.classList.add('slide-in-up-left')
          observer.unobserve(entry.target)
        }
      }
    }

    const marketplace_banner_image_observer = new IntersectionObserver(handle_intersection)

    return {
      marketplace_banner_image,

      marketplace_banner_image_observer
    }
  },
  mounted() {
    this.marketplace_banner_image_observer.observe(this.marketplace_banner_image)
  }
};
</script>

<style scoped>
.marketplace-banner {
  background: var(--mainLightGreenColor);
  width: 100%;
  max-height: 470px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  padding-top: 20px;
}

.marketplace-banner-content {
  padding: 10px 0px 10px 160px;
  width: 70%;
  margin-right: 300px;
}

.marketplace-banner-content h4 {
  font-family: var(--mainFontFamily);
  font-style: normal;
  font-weight: 600;
  font-size: 52px;
  line-height: 65px;
  /* margin-top: -40px; */
  margin-bottom: 30px;
  color: #181818;
}

.marketplace-banner-img {
  padding-top: 50px;
}

@media screen and (max-width: 1480px) {
  .marketplace-banner {
    max-height: 470px;
    padding-top: 20px;
  }

  .marketplace-banner-content {
    padding: 10px 0px 10px 100px;
    width: 65%;
    margin-right: 100px;
  }
}

@media screen and (max-width: 1290px) {
  .marketplace-banner {
    max-height: 470px;
    padding-top: 20px;
  }

  .marketplace-banner-content {
    padding: 10px 0px 10px 100px;
    width: 69%;
    margin-right: 50px;
  }

  .marketplace-banner-content h4 {
    font-size: 49px;
    line-height: 65px;
  }
}
@media screen and (max-width: 1200px) {
  .marketplace-banner {
    padding-top: 0px;
  }

  .marketplace-banner-content {
    padding: 10px 20px 10px 80px;
    /* width: 880px; */
  }

  .marketplace-banner-content h4 {
    font-size: 40px;
  }
}

@media screen and (max-width: 1050px) {
  .marketplace-banner {
    padding-top: 0px;
    max-height: 360px;
  }

  .marketplace-banner-content {
    padding: 10px 20px 10px 40px;
    width: 70%;
    margin-right: 0px;
  }

  .marketplace-banner-content h4 {
    font-size: 34px;
  }
}

@media screen and (max-width: 900px) {
  .marketplace-banner-content {
    padding: 10px 40px 10px 50px;
    /* width: 800px; */
  }

  .marketplace-banner-content h4 {
    font-size: 34px;
  }

  .marketplace-banner-content p {
    font-size: 19px;
  }
}

@media screen and (max-width: 800px) {
  .marketplace-banner-content {
    padding: 10px 20px 10px 20px;
    width: 90%;
  }

  .marketplace-banner-content h4 {
    font-size: 32px;
    line-height: 39px;
  }
}
@media screen and (max-width: 785px) {
  .marketplace-banner-content {
    padding: 10px 40px 10px 30px;
    width: 100%;
  }

  .marketplace-banner-content h4 {
    font-size: 27px;
    margin-bottom: 14px;
  }

  .marketplace-banner-content p {
    font-size: 18px;
  }

  .marketplace-banner-img {
    padding-top: 0px;
  }
}

@media screen and (max-width: 605px) {
  .marketplace-banner {
    padding-bottom: 40px;
    min-height: 300px;
  }

  .marketplace-banner-img {
    display: none;
  }

  .marketplace-banner-content {
    padding: 10px 40px 10px 40px;
    width: 100%;
    text-align: center;
  }

  .marketplace-banner-content h4 {
    font-size: 29px;
    margin-bottom: 14px;
  }
}

@media screen and (max-width: 505px) {
  .marketplace-banner {
    padding-bottom: 0px;
    min-height: 240px;
  }

  .marketplace-banner-content h4 {
    font-size: 23px;
    margin-bottom: 14px;
  }
}
</style>
