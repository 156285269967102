<template>
  <div>
    <HelpCenterBanner :title="title" :type="'FAQ'" />
    <OpenRolesAccordion :type="'FAQ'" :header="header" :data="faqs" />
  </div>
</template>

<script>
import HelpCenterBanner from "../components/extras/banners/HelpCenterBanner.vue";
import OpenRolesAccordion from "../components/extras/OpenRolesAccordion.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    HelpCenterBanner,
    OpenRolesAccordion,
  },
  computed: {
    ...mapGetters(["faqs"]),
  },

  data() {
    return {
      title: "FAQ",
      header: "Frequently Asked Questions",
    };
  },
  methods: {
    ...mapActions(["setCurrentPageName"]),
  },

  mounted() {
    this.setCurrentPageName(this.$router.currentRoute._rawValue.name);
  },
};
</script>
