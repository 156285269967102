<template>
  <div>
    <TokenizePropertyBanner :bannerType="'DESC'" />
    <TokenizeJourney />
  </div>
</template>

<script>
import TokenizePropertyBanner from "../components/extras/banners/TokenizePropertyBanner.vue";
import TokenizeJourney from "../components/extras/TokenizeJourney.vue";
import { mapActions } from "vuex";
export default {
  components: {
    TokenizePropertyBanner,
    TokenizeJourney,
  },

  methods: {
    ...mapActions(["setCurrentPageName"]),
  },

  mounted() {
    this.setCurrentPageName(this.$router.currentRoute._rawValue.name);
  },
};
</script>
