<template>
  <div>
    <HelpCenterBanner :title="title" :type="'HELP'" />
    <HelpCenterBox />
  </div>
</template>

<script>
import HelpCenterBanner from "../components/extras/banners/HelpCenterBanner.vue";
import HelpCenterBox from "../components/extras/HelpCenterBox.vue";
import { mapActions } from "vuex";
export default {
  components: {
    HelpCenterBanner,
    HelpCenterBox,
  },
  data() {
    return {
      title: "Help Center",
    };
  },

  methods: {
    ...mapActions(["setCurrentPageName"]),
  },

  mounted() {
    this.setCurrentPageName(this.$router.currentRoute._rawValue.name);
  },
};
</script>
