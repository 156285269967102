<template>
  <div class="tokenize-journey">
    <!-- <div class="tokenize-journey-top">
      <h4>Start Your Tokenization <span class="text-yellow">Journey</span></h4>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis fusce id
        neque leo, curabitur quis. Ut amet, malesuada eget et ac urna, diam. Non
        dictum non odio elit a ante ante. Sed etiam viverra ipsum lacus feugiat
        ac risus.
     
      </p>
    </div> -->
    <div class="tokenize-journey-onboarding">
      <h4 class="text-green">Relsify Onboarding</h4>

      <form class="" @submit.prevent="submitForm">
        <div class="tokenize-journey-onboarding-form">
          <div class="relsify-form-item">
            <div class="form-group">
              <label>Developer's first name</label>
              <input
                @input="validateForm"
                v-model="developerName"
                type="text"
                placeholder="e.g John Doe"
              />
            </div>
          </div>
          <div class="relsify-form-item">
            <div class="form-group">
              <label>Developer's email</label>
              <input
                @input="validateForm"
                v-model="developerEmail"
                type="email"
                placeholder="e.g doe@gmail.com"
              />
            </div>
          </div>
          <div class="relsify-form-item">
            <div class="form-group">
              <label>Country</label>
              <div
                v-if="countries"
                style="
                  display: grid;
                  grid-template-columns: 25px 1fr;
                  column-gap: 10px;
                "
              >
                <img
                  style="
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    border-radius: 100%;
                  "
                  v-if="dialCodeSelected && dialCodeSelected.flag"
                  :src="dialCodeSelected.flag"
                  alt=""
                />
                <select @change="selectCountryCode" v-model="dialCodeSelected">
                  <option
                    v-for="(countryCode, index) in countries"
                    :key="index"
                    :value="countryCode"
                  >
                    {{ countryCode.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="relsify-form-item">
            <div class="form-group">
              <label>Property City</label>
              <input
                @input="validateForm"
                v-model="propertyCity"
                placeholder="property city"
                type="text"
              />
            </div>
          </div>
          <div class="relsify-form-item">
            <div class="form-group">
              <label>Phone number</label>
              <div
                style="
                  display: grid;
                  grid-template-columns: auto 1fr;
                  column-gap: 10px;
                  align-items: center;
                "
              >
                <span v-if="dialCodeSelected"
                  ><b>{{ dialCodeSelected.dialCode }}</b></span
                >
                <input
                  type="text"
                  @input="validateForm"
                  v-model="developerPhoneNumber"
                  placeholder="phone number"
                />
              </div>
            </div>
          </div>
          <div class="relsify-form-item">
            <div class="form-group">
              <label>The property estimated value</label>
              <input
                @input="validateForm"
                v-model="propertyEstimatedValue"
                placeholder="property estimated value"
                type="text"
              />
            </div>
          </div>
          <div class="relsify-form-item">
            <div class="form-group">
              <label
                >What type of property are you proposing?
                (commercial/residential)</label
              >
              <select @change="validateForm">
                <option value="" disabled>Select property type</option>
                <option value="residential">Residential</option>
                <option value="industrial">Industrial</option>
                <option value="commercial">Commercial</option>
                <option value="agricultural">Agricultural</option>
                <option value="others">Others</option>
              </select>
            </div>
          </div>
          <div class="relsify-form-item">
            <div class="form-group">
              <label>Property address</label>
              <input
                @input="validateForm"
                v-model="propertyAddress"
                placeholder="property address"
                type="text"
              />
            </div>
          </div>
          <div class="relsify-form-item">
            <div class="form-group">
              <label>The legal title on the proposed property</label>
              <input
                @input="validateForm"
                v-model="legalTitle"
                placeholder="legal title"
                type="text"
              />
            </div>
          </div>
          <div class="relsify-form-item">
            <div class="form-group">
              <label>Money needed to be raised</label>
              <input
                @input="validateForm"
                v-model="toBeRaisedAmount"
                type="text"
              />
            </div>
          </div>

          <div class="relsify-form-item">
            <div class="form-group">
              <label
                >As a developer, what is your projected return on investment for
                investors?</label
              >
              <input
                v-model="projectedRoi"
                placeholder="projected ROI"
                type="text"
                @input="validateForm"
              />
            </div>
          </div>

          <div class="relsify-form-item">
            <div class="form-group">
              <label
                >Are you willing to pay dividend monthly, quarterly, or
                yearly?</label
              >

              <select v-model="dividentPay" @change="validateForm">
                <option value="" disabled>Select divident Pay</option>
                <option value="monthly">Monthly</option>
                <option value="quarterly">Quarterly</option>
                <option value="yearly">Yearly</option>
              </select>
            </div>
          </div>
          <div class="relsify-form-item">
            <div class="form-group">
              <label>What is the minimum ROI period?</label>
              <input
                @input="validateForm"
                v-model="minimumRoiPeriod"
                placeholder="e.g 1 year"
                type="text"
              />
            </div>
          </div>
          <div class="relsify-form-item">
            <div class="form-group">
              <label
                >How many years of experience do you have as a developer?</label
              >
              <input
                @input="validateForm"
                v-model="developerExperience"
                type="text"
              />
            </div>
          </div>
          <div class="relsify-form-item">
            <div class="form-group">
              <label>How many projects have you completed successfully?</label>
              <input
                @input="validateForm"
                v-model="projectsCompleted"
                placeholder="Proposed projects completed"
                type="text"
              />
            </div>
          </div>
          <div class="relsify-form-item">
            <div class="form-group">
              <label>How much have you sold out on the proposed project?</label>
              <input
                @input="validateForm"
                v-model="soldOutOnProposedProject"
                placeholder="Sold outs on proposed projects"
                type="text"
              />
            </div>
          </div>
          <div class="relsify-form-item">
            <div class="form-group">
              <label
                >When do you plan to onboard investors on the relsify
                platform?</label
              >
              <input
                @input="validateForm"
                v-model="investordOnboardingDate"
                type="date"
              />
            </div>
          </div>
          <div class="relsify-form-item">
            <div class="form-group">
              <label>Link to developer's company profile</label>
              <input
                @input="validateForm"
                v-model="linkToCompanyProfile"
                placeholder="e.g http://google.com"
                type="text"
              />
            </div>
          </div>
          <div class="relsify-form-item">
            <div class="form-group">
              <label>Link to company's website</label>
              <input
                @input="validateForm"
                v-model="linkToCompanyWebsite"
                placeholder="e.g http://google.com"
                type="text"
              />
            </div>
          </div>
          <div class="relsify-form-item">
            <div class="form-group">
              <label>Link to company's bronchure</label>
              <input
                @input="validateForm"
                v-model="linkToCompanyBronchure"
                placeholder="e.g http://google.com"
                type="text"
              />
            </div>
          </div>
        </div>
        <!-- <div class="tokenize-journey-onboarding-form">
          <div class="relsify-form-item">
            <div class="form-group">
              <label
                >Are you in possession of any of the following documents?
                (Select all that apply)</label
              >
              <div class="form_checkbox">
                <label for="privatePlacement">
                  <input type="checkbox" id="privatePlacement" />

                  <span>Private Placement Memorandum (PPM)</span>
                </label>
              </div>
              <div class="form_checkbox">
                <label for="subscriptionAgreement">
                  <input type="checkbox" id="subscriptionAgreement" />

                  <span>Subscription Agreement</span>
                </label>
              </div>
              <div class="form_checkbox">
                <label for="marketingmaterials">
                  <input type="checkbox" id="marketingmaterials" />
                  <span>Marketing Materials</span>
                </label>
              </div>
              <div class="form_checkbox">
                <label for="offeringMemo">
                  <input type="checkbox" id="offeringMemo" />
                  <span>Offering Memorandum</span>
                </label>
              </div>
            </div>
          </div>
          <div class="relsify-form-item">
            <div class="form-group">
              <label
                >How much do you plan to raise through your security token
                offering (STO)?</label
              >
              <div class="form_checkbox">
                <label for="secToken">
                  <input type="radio" name="security_token" id="secToken" />

                  <span><i class="bx bx-chevron-left"></i>$2M</span>
                </label>
              </div>
              <div class="form_checkbox">
                <label for="sectoken2m">
                  <input type="radio" name="security_token" id="sectoken2m" />

                  <span>$2m - $10M</span>
                </label>
              </div>
              <div class="form_checkbox">
                <label for="sectoken10m">
                  <input type="radio" name="security_token" id="sectoken10m" />

                  <span>$10M - $50M</span>
                </label>
              </div>
              <div class="form_checkbox">
                <label for="secToken">
                  <input type="radio" name="security_token" id="secToken" />

                  <span><i class="bx bx-chevron-left"></i>$50M</span>
                </label>
              </div>
            </div>
          </div>
          <div class="relsify-form-item">
            <div class="form-group">
              <label
                >Have you already raised capital for this offering? If so, how
                much?</label
              >
              <div class="form_checkbox">
                <label for="capitOff1">
                  <input type="radio" name="capitalOffer" id="capitOff1" />

                  <span><i class="bx bx-chevron-left"></i>$500K</span>
                </label>
              </div>
              <div class="form_checkbox">
                <label for="capitOff2">
                  <input type="radio" name="capitalOffer" id="capitOff2" />

                  <span>$500k - $2M</span>
                </label>
              </div>
              <div class="form_checkbox">
                <label for="capitOff3">
                  <input type="radio" name="capitalOffer" id="capitOff3" />

                  <span>> $2M</span>
                </label>
              </div>
              <div class="form_checkbox">
                <label for="capitOff4">
                  <input type="radio" name="capitalOffer" id="capitOff4" />

                  <span>N/A – Have not raised any capital</span>
                </label>
              </div>
            </div>
          </div>
          <div class="relsify-form-item">
            <div class="form-group">
              <label>When do you plan to start your STO?</label>
              <div class="form_checkbox">
                <label for="yourSTO1">
                  <input type="radio" name="yourSTO" id="yourSTO1" />

                  <span><i class="bx bx-chevron-left"></i>1M</span>
                </label>
              </div>
              <div class="form_checkbox">
                <label for="yourSTO2">
                  <input type="radio" name="yourSTO" id="yourSTO2" />

                  <span>1-3 Months</span>
                </label>
              </div>
              <div class="form_checkbox">
                <label for="yourSTO3">
                  <input type="radio" name="yourSTO" id="yourSTO3" />

                  <span>3-6 Months</span>
                </label>
              </div>
              <div class="form_checkbox">
                <label for="yourSTO4">
                  <input type="radio" name="yourSTO" id="yourSTO4" />

                  <span>> 6 Months</span>
                </label>
              </div>
            </div>
          </div>
          <div class="relsify-form-item">
            <div class="form-group">
              <label>Are you an experienced / active investor?</label>
              <div class="form_checkbox">
                <label for="activInvest1">
                  <input type="radio" name="activInvest" id="activInvest1" />

                  <span>Yes</span>
                </label>
              </div>
              <div class="form_checkbox">
                <label for="activInves2">
                  <input type="radio" name="activInvest" id="activInves2" />

                  <span>No</span>
                </label>
              </div>
            </div>
          </div>
          <div class="relsify-form-item">
            <div class="form-group">
              <label>How many years of exprience do you have </label>
              <div class="form_checkbox">
                <label for="yearsExp1">
                  <input type="radio" name="yearsExp" id="yearsExp1" />

                  <span>0 - 5years</span>
                </label>
              </div>
              <div class="form_checkbox">
                <label for="yearsExp2">
                  <input type="radio" name="yearsExp" id="yearsExp2" />

                  <span>5 - 10years</span>
                </label>
              </div>
              <div class="form_checkbox">
                <label for="yearsExp3">
                  <input type="radio" name="yearsExp" id="yearsExp3" />

                  <span>>10years</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="tokenize-journey-onboarding-form">
          <div class="relsify-form-item">
            <div class="form-group">
              <label>
                <b>Message</b> <br />
                *To guide the discussion, please give us details on your
                interest in speaking with the Relsify Team.
              </label>
            </div>
          </div>
        </div>
        <div
          class="tokenize-journey-onboarding-form tokenize-journey-onboarding-form-full"
        >
          <div class="relsify-form-item">
            <div class="form-group">
              <textarea cols="30" rows="10"></textarea>
            </div>
          </div>
        </div>
        <div class="tokenize-journey-onboarding-form mt-0">
          <div class="relsify-form-item">
            <div class="form_checkbox">
              <label for="agrreeterms">
                <input type="checkbox" id="agrreeterms" />

                <span
                  >I agree to get updates and useful info pertaining to
                  tokenization offerings from Relsify</span
                >
              </label>
            </div>
          </div>
        </div> -->
        <div class="form-group text-center">
          <transition name="fade">
            <p
              v-if="formMessage"
              style="
                color: #a80024 !important;
                font-size: 16px !important;
                font-weight: 900 !important;
                margin-top: 28px;
                margin-bottom: 20px;
                text-align: center !important;
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                viewBox="0 0 20 20"
                svg-inline=""
                role="presentation"
                focusable="false"
                tabindex="-1"
                style="color: #a80024 !important"
                fill="#a80024"
              >
                <path
                  d="M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0zm0 11c-.55 0-1-.45-1-1V6c0-.55.45-1 1-1s1 .45 1 1v4c0 .55-.45 1-1 1zm1 4H9v-2h2v2z"
                ></path>
              </svg>
              {{ formMessage }}
            </p>
          </transition>
        </div>
        <div class="text-center mt-4">
          <br /><br />
          <button :disabled="emptyFields" class="relsify-button btn-large">
            <span v-if="!loading">Submit </span>
            <span class="loader" v-if="loading"></span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["countries"]),
    dialCodeSelected: {
      get() {
        return this.$store.state.dialCodeSelected;
      },
      set(value) {
        return (this.$store.state.dialCodeSelected = value);
      },
    },
  },

  data() {
    return {
      developerName: "",
      developerEmail: "",
      developerPhoneNumber: "",
      countrySelected: null,
      country: null,
      propertyEstimatedValue: "",
      propertyType: "",
      propertyAddress: "",
      propertyCity: "",
      legalTitle: "",
      projectedRoi: "",
      dividentPay: "",
      toBeRaisedAmount: "",
      minimumRoiPeriod: "",
      developerExperience: "",
      projectsCompleted: "",
      soldOutOnProposedProject: "",
      investordOnboardingDate: "",
      linkToCompanyProfile: "",
      linkToCompanyWebsite: "",
      linkToCompanyBronchure: "",
      emptyFields: true,
      loading: false,
      formMessage: "",
    };
  },

  methods: {
    ...mapActions(["setAlertpopUp"]),
    selectCountryCode: function () {
      if (this.dialCodeSelected) {
        this.country = this.dialCodeSelected.name;
      }
      this.validateForm();
    },
    validateForm: function () {
      if (
        !this.developerName ||
        !this.developerEmail ||
        !this.developerPhoneNumber ||
        !this.propertyEstimatedValue ||
        !this.propertyType ||
        !this.propertyAddress ||
        !this.propertyCity ||
        !this.legalTitle ||
        !this.projectedRoi ||
        !this.dividentPay ||
        !this.toBeRaisedAmount ||
        !this.minimumRoiPeriod ||
        !this.developerExperience ||
        !this.projectsCompleted ||
        !this.soldOutOnProposedProject ||
        !this.investordOnboardingDate ||
        !this.linkToCompanyProfile ||
        !this.linkToCompanyWebsite ||
        !this.linkToCompanyBronchure
      ) {
        this.emptyFields = true;
        this.formMessage = "Please out all fields";

        return false;
      }

      this.emptyFields = false;
      this.formMessage = "";

      return true;
    },
    submitForm: function () {
      this.loading = true;
      let url = `${this.RELSIFY_URL}/api/contact-form`;
      var requestPayload = {
        developerName: this.developerName,
        developerEmail: this.developerEmail,
        developerPhoneNumber: this.developerPhoneNumber,
        propertyEstimatedValue: this.propertyEstimatedValue,
        propertyType: this.propertyType,
        propertyAddress: this.propertyAddress,
        propertyCity: this.propertyCity,
        legalTitle: this.legalTitle,
        projectedRoi: this.projectedRoi,
        dividentPay: this.dividentPay,
        toBeRaisedAmount: this.toBeRaisedAmount,
        minimumRoiPeriod: this.minimumRoiPeriod,
        developerExperience: this.developerExperience,
        projectsCompleted: this.projectsCompleted,
        soldOutOnProposedProject: this.soldOutOnProposedProject,
        investordOnboardingDate: this.investordOnboardingDate,
        linkToCompanyProfile: this.linkToCompanyProfile,
        linkToCompanyWebsite: this.linkToCompanyWebsite,
        linkToCompanyBronchure: this.linkToCompanyBronchure,
      };
      this.axios
        .post(url, requestPayload)
        .then((data) => {
          setTimeout(() => {
            this.loading = false;
            if (!data.data.error) {
              console.log(data.data);
              this.setAlertpopUp({
                status: true,
                title: "onboarding Submitted successfully!",
                des: data.data.meta.message,
                type: "success",
                payload: null,
              });
              this.clearFormFields();
            } else {
              this.setAlertpopUp({
                status: true,
                title: "Message Not Sent!",
                des: data.data.meta.message,
                type: "error",
                payload: null,
              });
            }
          }, 3000);
        })
        .catch(() => {
          this.loading = false;
          this.setAlertpopUp({
            status: true,
            title: "Message Not Sent!",
            des: "An Error occurred!",
            type: "error",
            payload: null,
          });
        });
    },

    clearFormFields: function () {
      this.developerName = "";
      this.developerEmail = "";
      this.developerPhoneNumber = "";
      this.countrySelected = null;
      this.country = null;
      this.propertyEstimatedValue = "";
      this.propertyType = "";
      this.propertyAddress = "";
      this.propertyCity = "";
      this.legalTitle = "";
      this.projectedRoi = "";
      this.dividentPay = "";
      this.toBeRaisedAmount = "";
      this.minimumRoiPeriod = "";
      this.developerExperience = "";
      this.projectsCompleted = "";
      this.soldOutOnProposedProject = "";
      this.investordOnboardingDate = "";
      this.linkToCompanyProfile = "";
      this.linkToCompanyWebsite = "";
      this.linkToCompanyBronchure = "";
      this.emptyFields = true;
      this.loading = false;
      this.formMessage = "";
    },
  },

  mounted() {},
};
</script>

<style scoped>
[class*="loader"]:before {
  content: "Please wait...";
}
.tokenize-journey {
  background: #fff;
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
}

.tokenize-journey-top {
  width: 800px;
  margin: auto;
  text-align: center;
}

.tokenize-journey-top h4 {
  font-family: var(--mainFontFamily);
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
  margin-bottom: 30px;
  color: #181818;
}

.tokenize-journey-top p {
  font-family: var(--mainFontFamily);
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #363636;
}

.tokenize-journey-onboarding {
  width: 65%;
  margin: -180px auto 20px;
  background: #ffffff;
  padding: 50px 40px 120px;
  box-shadow: 0px 6px 8px 8px rgba(189, 189, 189, 0.22);
  border-radius: 4px;
}

.tokenize-journey-onboarding h4 {
  font-family: var(--mainFontFamily);
  text-align: center;
  font-weight: 600;
  font-size: 31px;
  line-height: 47px;
}

.tokenize-journey-onboarding-form {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 35px;
  margin-top: 50px;
  font-family: var(--mainFontFamily);
}

.tokenize-journey-onboarding-form-full {
  display: block !important;
  width: 75%;
  margin-top: 0px !important;
}

@media screen and (max-width: 1200px) {
  .tokenize-journey {
    padding-top: 80px;
  }

  .tokenize-journey-top {
    width: 800px;
  }

  .tokenize-journey-top h4 {
    font-size: 30px;
  }

  .tokenize-journey-top p {
    font-size: 16px;
  }

  .tokenize-journey-onboarding {
    width: 85%;
    /* margin: 70px auto 20px; */
    padding: 50px 40px 120px;
  }

  .tokenize-journey-onboarding h4 {
    font-size: 28px;
  }
}

@media screen and (max-width: 1000px) {
  .tokenize-journey-onboarding {
    width: 90%;
    margin: -130px auto 20px;
    padding: 50px 40px 100px;
  }
}
@media screen and (max-width: 900px) {
  .tokenize-journey {
    padding-top: 40px;
  }

  .tokenize-journey-top {
    padding: 10px 40px 10px 50px;
    width: 800px;
  }

  .tokenize-journey-top h4 {
    font-size: 34px;
  }

  .tokenize-journey-top p {
    font-size: 19px;
  }
}

@media screen and (max-width: 850px) {
  .tokenize-journey-onboarding {
    margin: -80px auto 20px;
  }
  .tokenize-journey-onboarding-form {
    display: block;
  }
}

@media screen and (max-width: 785px) {
  .tokenize-journey-top {
    width: auto;
  }

  .tokenize-journey-top h4 {
    font-size: 30px;
  }

  .tokenize-journey-top p {
    font-size: 16px;
  }
}

@media screen and (max-width: 700px) {
  .tokenize-journey-onboarding {
    width: 100%;
    padding: 50px 40px 100px;
  }

  .tokenize-journey-onboarding-form-full {
    width: 100%;
  }
}

@media screen and (max-width: 605px) {
  .tokenize-journey {
    padding-bottom: 40px;
  }
  .tokenize-journey-img {
    display: none;
  }

  .tokenize-journey-top {
    padding: 10px 40px 10px 40px;
    width: 100%;
    text-align: center;
  }

  .tokenize-journey-onboarding {
    width: 100%;
    padding: 50px 25px 20px;
    box-shadow: none;
  }
}

@media screen and (max-width: 550px) {
  .tokenize-journey-top h4 {
    font-size: 25px;
    line-height: 37px;
  }

  .tokenize-journey-top p {
    font-size: 15px;
  }

  .tokenize-journey-onboarding {
    padding: 50px 15px 20px;
    /* margin-top: 10px; */
  }

  .tokenize-journey-onboarding h4 {
    font-size: 26px;
  }
}
</style>
