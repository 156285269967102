<template>
  <div class="help-center-box" v-if="faqs && allFaqs">
    <div class="help-center-box-menu">
      <div
        class="help-center-box-menu-item"
        v-for="(faq, index) in allFaqs"
        :key="index"
        @click="goToFaq(faq)"
      >
        <img src="@/assets/img/vectors/fingerprint-phone.png" alt="" />
        <p>{{ faq.category }}</p>
        <div class="help-center-box-ellipse">
          <img src="@/assets/img/vectors/Ellipse.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["faqs"]),
    allFaqs() {
      let faqs = null;
      if (this.faqs && this.faqs.length) {
        faqs = this.eliminateDuplicateCategory(this.faqs);
      }

      return faqs;
    },
  },
  methods: {
    goToFaq: function (faq) {
      // console.log(faq);
      this.$router.push({
        name: "FAQFilter",
        query: { category: faq.category },
      });
    },

    eliminateDuplicateCategory(arr) {
      return arr
        .map((e) => e["category"])
        .map((e, i, final) => final.indexOf(e) === i && i)
        .filter((obj) => arr[obj])
        .map((e) => arr[e]);
    },
  },
};
</script>

<style scoped>
.help-center-box {
  background: #ffffff;
  width: 70%;
  margin: -70px auto 100px;
  box-shadow: 0px 4px 17px 3px rgba(150, 150, 150, 0.25);
  border-radius: 20px;
  padding: 50px 120px 100px;
}

.help-center-box-menu {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 80px;
}

.help-center-box-menu .help-center-box-menu-item {
  text-align: center;
  padding: 15px 0px;
  background: linear-gradient(0deg, #e9fffa, #e9fffa), #fcfcfc;
  border-radius: 11.521px;
  position: relative;
  cursor: pointer;
}

.help-center-box-menu .help-center-box-menu-item:nth-of-type(even) {
  background: linear-gradient(0deg, #fffaf2, #fffaf2), #fcfcfc;
}

.help-center-box-menu .help-center-box-menu-item img {
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.help-center-box-menu .help-center-box-menu-item p {
  font-size: 14px;
  font-style: normal;
  font-weight: 590;
  line-height: 23px;
  text-align: center;
  letter-spacing: -0.276504px;
  color: var(--mainGreenColor);
  margin-top: 16px;
}

.help-center-box-ellipse {
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.help-center-box-ellipse img {
  width: 15px;
}

.help-center-box-faq {
  margin-top: 80px;
}

.help-center-box-faq h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 54px;
  letter-spacing: -0.609256px;
  color: #000000;
}

.help-center-box-faq-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  margin-top: 40px;
}

.help-center-box-faq-grid-item {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.help-center-box-faq-grid-item i {
  font-size: 17px;
  background: #e7fffa;
  padding: 8px;
  border-radius: 100%;
  font-weight: bold;
  color: var(--mainGreenColor);
}

.help-center-box-faq-grid-item p {
  margin: 0px;
  margin-left: 10px;
  font-size: 14px;
}

@media screen and (max-width: 1170px) {
  .help-center-box {
    width: 90%;
    margin: -70px auto 100px;
    padding: 50px 120px 100px;
  }
}

@media screen and (max-width: 1050px) {
  .help-center-box {
    padding: 50px 80px 70px;
  }
}

@media screen and (max-width: 950px) {
  .help-center-box-menu {
    grid-template-columns: repeat(2, 1fr);
    gap: 80px;
  }

  .help-center-box-faq-grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
  }
}

@media screen and (max-width: 800px) {
  .help-center-box {
    padding: 50px 70px 100px;
  }
  /* .help-center-box-menu {
    grid-template-columns: repeat(2, 1fr);
    gap: 80px;
  }

  .help-center-box-faq-grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
  } */
}

@media screen and (max-width: 700px) {
  .help-center-box {
    padding: 50px 40px 100px;
  }

  .help-center-box-menu {
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
  }
}

@media screen and (max-width: 650px) {
  .help-center-box {
    padding: 50px 30px 70px;
  }

  .help-center-box-menu {
    grid-template-columns: repeat(2, 1fr);
    gap: 40px 20px;
  }

  .help-center-box-faq-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }
}

@media screen and (max-width: 600px) {
  .help-center-box {
    padding: 50px 30px 70px;
  }

  .help-center-box-menu .help-center-box-menu-item img {
    width: 25px;
    height: 25px;
  }

  .help-center-box-ellipse img {
    width: 15px;
  }

  .help-center-box-menu .help-center-box-menu-item p {
    font-size: 13px;
    font-weight: bold;
  }
}
@media screen and (max-width: 480px) {
  .help-center-box-menu {
    display: flex;
    flex-direction: column;
    gap: 40px 20px;
  }

  .help-center-box-menu .help-center-box-menu-item {
    padding: 22px 0px;
  }

  .help-center-box-faq-grid {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .help-center-box-faq {
    margin-top: 40px;
  }

  .help-center-box-faq h5 {
    font-size: 27px;
    text-align: center;
  }

  .help-center-box-menu
    .help-center-box-menu-item
    .help-center-box-ellipse
    img {
    width: auto;
    height: 70px;
  }
}
</style>
