<template>
  <div class="tokenize-banner">
    <div class="tokenize-banner-content">
      <h4 v-if="bannerType == 'FORM'">
        Tokenise Your <span class="text-green">Property</span>
      </h4>

      <p v-if="bannerType == 'FORM'" ref="tokenize_banner_desc" class="tokenize_banner_desc">
        We create asset-backed <b style="color: #111">security tokens</b> to
        help security issuers raise more capital and offer investors true
        liquidity.
      </p>
      <p v-if="bannerType == 'DESC'" ref="tokenize_banner_desc" class="tokenize_banner_desc">
        <q
          >You don't have to do anything wrong to get kicked out of the real
          estate market, you just have to ignore cutting edge trends knowingly
          or unknowingly while others adopt them.</q
        >
      </p>
    </div>
    <div ref="tokenize_banner_image" class="tokenize-banner-img tokenize_banner_image">
      <img style="" src="@/assets/img/building.png" alt="" />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  props: ["bannerType"],
  setup() {
    const tokenize_banner_image = ref(null)
    const tokenize_banner_desc = ref(null)

    const handle_intersection = ([entry], observer) => {
      if (entry.isIntersecting) {
        if (entry.target.classList.contains('tokenize_banner_image')) {
          entry.target.children[0].classList.add('slide-in-up-left')
          observer.unobserve(entry.target)
        } else if (entry.target.classList.contains === 'tokenize_banner_desc') {
          entry.target.classList.add('zoom-in')
          observer.unobserve(entry.target)
        }
      }
    }

    const tokenize_banner_image_observer = new IntersectionObserver(handle_intersection)
    const tokenize_banner_desc_observer = new IntersectionObserver(handle_intersection)

    return {
      tokenize_banner_image,
      tokenize_banner_desc,

      tokenize_banner_image_observer,
      tokenize_banner_desc_observer,
    }
  },
  mounted() {
    this.tokenize_banner_desc_observer.observe(this.tokenize_banner_desc)
    this.tokenize_banner_image_observer.observe(this.tokenize_banner_image)
  }
};
</script>

<style scoped>
.tokenize-banner {
  background: var(--mainLightGreenColor);
  width: 100%;
  max-height: 600px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  padding-top: 20px;
}

.tokenize-banner-content {
  padding: 0px 0px 10px 160px;
  width: 800px;
}

.tokenize-banner-content h4 {
  font-family: var(--mainFontFamily);
  font-style: normal;
  font-weight: 600;
  font-size: 44px;
  line-height: 81px;
  margin-top: -40px;
  margin-bottom: 30px;
  color: #181818;
}

.tokenize-banner-content p {
  font-family: var(--mainFontFamily);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 140.62%;
  color: #363636;
}

.tokenize-banner-content p q {
  font-size: 15px;
}

.tokenize-banner-img {
  overflow: hidden;
  z-index: -1;
}

@media screen and (max-width: 1200px) {
  .tokenize-banner {
    padding-top: 80px;
  }

  .tokenize-banner-content {
    padding: 10px 20px 10px 80px;
    width: 880px;
  }

  .tokenize-banner-content h4 {
    font-size: 39px;
  }

  .tokenize-banner-content p {
    font-size: 20px;
  }
}

@media screen and (max-width: 900px) {
  .tokenize-banner {
    padding-top: 40px;
  }

  .tokenize-banner-content {
    padding: 10px 40px 10px 90px;
    width: 800px;
  }

  .tokenize-banner-content h4 {
    font-size: 34px;
  }

  .tokenize-banner-content p {
    font-size: 19px;
  }
}

@media screen and (max-width: 785px) {
  .tokenize-banner {
    padding-top: 40px;
    max-height: 700px;
  }

  .tokenize-banner-content {
    padding: 10px 40px 10px 30px;
    width: auto;
  }

  .tokenize-banner-content h4 {
    font-size: 30px;
    margin-bottom: 14px;
  }

  .tokenize-banner-content p {
    font-size: 18px;
  }
}

@media screen and (max-width: 605px) {
  .tokenize-banner {
    padding-bottom: 40px;
    min-height: 360px;
  }

  .tokenize-banner-img {
    display: none;
  }

  .tokenize-banner-content {
    padding: 10px 40px 10px 40px;
    width: 100%;
    text-align: center;
  }
}

@media screen and (max-width: 520px) {
  .tokenize-banner {
    padding-bottom: 20px;
    min-height: 300px;
  }

  .tokenize-banner-content {
    padding: 10px 20px 0px;
  }

  .tokenize-banner-content h4 {
    font-size: 29px;
    line-height: 33px;
    margin-bottom: 28px;
  }

  .tokenize-banner-content p {
    font-size: 16.5px;
  }
}
</style>
