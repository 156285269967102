<template>
  <div class="help-banner">
    <div class="help-banner-content">
      <h4>{{ title }}</h4>
      <form
        class="relsify-search"
        v-if="type == 'FAQ'"
        @submit.prevent="searchFaQ"
      >
        <i class="bx bx-search-alt-2"></i>
        <input disabled type="search" v-model="searchText" />
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "type"],
};
</script>

<style scoped>
.help-banner {
  background: var(--mainLightGreenColor);
  width: 100%;
  max-height: 600px;
  display: block;
  overflow: hidden;
  padding: 80px 270px 150px;
}

.help-banner-content {
  /* width: 58%; */
  margin: 0px;
}

.help-banner h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 61px;
  color: #000000;
  margin-bottom: 30px;
}

.help-banner-content .relsify-search {
  padding-top: 10px;
  padding-bottom: 10px;
  border: 2px solid var(--mainYellowColor);
}

@media screen and (max-width: 1400px) {
  .help-banner {
    padding: 80px 200px 150px;
  }
}
@media screen and (max-width: 1150px) {
  .help-banner {
    padding: 80px 200px 140px;
  }

  .help-banner h4 {
    font-size: 40px;
    /* margin-bottom: 60px; */
  }
}

@media screen and (max-width: 1000px) {
  .help-banner {
    padding: 80px 60px 130px !important;
  }
  .help-banner h4 {
    font-size: 35px;
    line-height: 41px;
  }
}

@media screen and (max-width: 900px) {
  .help-banner h4 {
    font-size: 30px;
  }
}

@media screen and (max-width: 720px) {
  .help-banner {
    padding: 60px 25px 100px !important;
    min-height: 300px;
  }

  .help-banner h4 {
    font-size: 25px;
  }
}

@media screen and (max-width: 580px) {
  .help-banner h4 {
    font-size: 27px;
  }

  .help-banner-content .relsify-search {
    padding-top: 7px;
    padding-bottom: 7px;
    border: 1px solid var(--mainYellowColor);
  }
}
@media screen and (max-width: 480px) {
  .help-banner h4 {
    font-size: 28px;
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 380px) {
  .help-banner h4 {
    font-size: 23px;
  }
}
</style>
