<template>
  <div>
    <ContactBanner />
    <ContactForm />
  </div>
</template>

<script>
import ContactBanner from "../components/extras/banners/ContactBanner.vue";
import ContactForm from "../components/extras/ContactForm.vue";
import { mapActions } from "vuex";
export default {
  components: {
    ContactBanner,
    ContactForm,
  },

  data() {
    return {};
  },
  methods: {
    ...mapActions(["setCurrentPageName"]),
  },

  mounted() {
    this.setCurrentPageName(this.$router.currentRoute._rawValue.name);
  },
};
</script>
