<template>
  <div class="join_the_team">
    <div class="join_the_team_wrapper">
      <h4>Interested In Joining <span class="text-green">Our Team ?</span></h4>
      <p>
        Discover how Relsify is leveraging blockchain technology as a global
        liquidity source for Africa's Real Estate Market towards reducing
        barrier entry for Investors and providing enormous funding capacity for
        Developers.
      </p>
      <h6>Be the first to know as things unveils…</h6>
      <div class="join_the_team_wrapper_button">
        <input type="text" placeholder="Your email" />
        <button>Join The Team</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.join_the_team {
  padding: 80px;
  background: #fff;
}

.join_the_team_wrapper {
  padding: 80px 170px;
  background: linear-gradient(
    111.76deg,
    rgba(24, 133, 111, 0.07) 5.6%,
    rgba(255, 174, 27, 0.07) 102.72%
  );
  border-radius: 77px;
  text-align: center;
}

.join_the_team_wrapper h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 67px;
  margin-bottom: 70px;
  color: #000000;
}

.join_the_team_wrapper p {
  width: 60%;
  margin: 10px auto;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 33px;
  text-align: center;
  color: #2d2d2d;
  margin-bottom: 30px;
}

.join_the_team_wrapper h6 {
  width: 60%;
  margin: 10px auto;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 33px;
  text-align: center;
  color: var(--mainGreenColor);
  margin-bottom: 40px;
}

.join_the_team_wrapper_button {
  width: 60%;
  margin: 10px auto;
}

.join_the_team_wrapper_button input {
  border: none;
  padding: 18px 25px;
  background: #ffffff;
  border-radius: 15px 0px 0px 15px;
  width: 60%;
  transition: all 500ms ease;
}

.join_the_team_wrapper_button input:focus {
  outline: none;
  padding-left: 20px;
}

.join_the_team_wrapper_button button {
  border: 2px solid var(--mainGreenColor);
  padding: 18px;
  background: var(--mainGreenColor);
  color: #fff;
  border-radius: 0px 15px 15px 0px;
  /* width: 50%; */
  transition: all 500ms ease;
}

.join_the_team_wrapper_button button:hover {
  border: 2px solid var(--mainGreenColor);
  background: #fff;
  color: var(--mainGreenColor);
}

@media screen and (max-width: 1400px) {
  .join_the_team_wrapper {
    padding: 80px;
  }

  .join_the_team_wrapper h4 {
    font-size: 49px;
    margin-bottom: 70px;
  }
}

@media screen and (max-width: 1200px) {
  .join_the_team {
    padding: 40px;
  }

  .join_the_team_wrapper {
    padding: 80px 50px;
  }

  .join_the_team_wrapper h4 {
    font-size: 48px;
    line-height: 57px;
    margin-bottom: 50px;
  }

  .join_the_team_wrapper p {
    width: 68%;
    margin: 10px auto;
    font-size: 17px;
    line-height: 30px;
    margin-bottom: 20px;
  }

  .join_the_team_wrapper h6 {
    width: 68%;
    margin: 10px auto;
    font-size: 15px;
    line-height: 30px;
    margin-bottom: 20px;
  }

  .join_the_team_wrapper_button {
    width: 70%;
    margin: 10px auto;
  }
}
@media screen and (max-width: 1000px) {
  .join_the_team_wrapper {
    padding: 80px 45px;
  }

  .join_the_team_wrapper h4 {
    font-size: 36px;
    line-height: 50px;
    margin-bottom: 40px;
  }

  .join_the_team_wrapper p {
    width: 78%;
    margin: 10px auto;
    font-size: 17px;
    line-height: 30px;
    margin-bottom: 20px;
  }

  .join_the_team_wrapper h6 {
    width: 78%;
    margin: 10px auto;
    font-size: 15px;
    line-height: 20px;
  }

  .join_the_team_wrapper_button {
    width: 80%;
    margin: 40px auto 10px;
  }

  .join_the_team_wrapper_button input {
    padding: 18px 25px;
    width: 64%;
  }
}

@media screen and (max-width: 810px) {
  .join_the_team_wrapper {
    padding: 50px 30px;
  }

  .join_the_team_wrapper h4 {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 40px;
  }

  .join_the_team_wrapper p {
    width: 90%;
    margin: 10px auto;
    font-size: 15px;
    line-height: 30px;
    margin-bottom: 20px;
  }

  .join_the_team_wrapper h6 {
    width: 90%;
    margin: 10px auto;
    font-size: 15px;
    line-height: 20px;
  }

  .join_the_team_wrapper_button {
    width: 88%;
    margin: 40px auto 10px;
  }

  .join_the_team_wrapper_button input {
    padding: 18px 25px;
    width: 70%;
  }
  .join_the_team_wrapper_button input,
  .join_the_team_wrapper_button button {
    font-size: 14px;
  }
}

@media screen and (max-width: 710px) {
  .join_the_team_wrapper_button input,
  .join_the_team_wrapper_button button {
    width: 100%;
    border-radius: 10px;
  }

  .join_the_team_wrapper_button button {
    margin-top: 20px;
  }
}

@media screen and (max-width: 640px) {
  .join_the_team {
    padding: 40px 15px;
  }

  .join_the_team_wrapper {
    border-radius: 37px;
  }

  .join_the_team_wrapper h4 {
    font-size: 22px;
    line-height: 40px;
    margin-bottom: 40px;
  }

  .join_the_team_wrapper p {
    width: 95%;
    margin: 10px auto 30px;
    font-size: 15px;
    line-height: 27px;
  }
}

@media screen and (max-width: 540px) {
  .join_the_team_wrapper {
    padding: 50px 19px;
  }

  .join_the_team_wrapper p,
  .join_the_team_wrapper h6,
  .join_the_team_wrapper_button {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .join_the_team {
    padding: 40px 0px;
  }

  .join_the_team_wrapper {
    padding: 50px 24px;
    border-radius: 0px;
  }

  .join_the_team_wrapper h4 {
    font-size: 18px;
    line-height: 40px;
    margin-bottom: 30px;
  }
  .join_the_team_wrapper p {
    line-height: 25px;
  }

  .join_the_team_wrapper_button input {
    padding: 10px 20px;
  }

  .join_the_team_wrapper_button button {
    padding: 10px 20px;
  }
}
</style>
