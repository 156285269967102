<template>
  <div>
    <HelpCenterBanner :title="title" :type="'FAQ'" />
    <OpenRolesAccordion :type="'FAQ'" :header="header" :data="faqByCategory" />
  </div>
</template>

<script>
import HelpCenterBanner from "../components/extras/banners/HelpCenterBanner.vue";
import OpenRolesAccordion from "../components/extras/OpenRolesAccordion.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    HelpCenterBanner,
    OpenRolesAccordion,
  },
  computed: {
    ...mapGetters(["faqs"]),
    faqByCategory() {
      let categories = null;
      if (this.faqs && this.faqs.length && this.$route.query.category) {
        categories = this.faqs
          .slice()
          .filter((faq) => faq.category == this.$route.query.category);
      }

      return categories;
    },
  },

  data() {
    return {
      title: "FAQ",
      header: "Frequently Asked Questions",
    };
  },
  methods: {
    ...mapActions(["setCurrentPageName"]),
  },

  mounted() {
    this.setCurrentPageName(this.$router.currentRoute._rawValue.name);
    if (this.$route.query.category) {
      this.title = `FAQ (${this.$route.query.category})`;
    } else {
      this.$router.push({ name: "Home" });
    }
  },
};
</script>
