<template>
  <div class="summary_inv">
    <h5>Location</h5>
    <p>City: {{ project.city }}</p>
    <p>State: {{ project.state }}</p>
    <p>
      Country:
      <span style="margin-left: 6px">
        <img
          v-if="countryFlag"
          :src="countryFlag"
          style="
            width: 10px;
            height: 15px;
            object-fit: contain;
            border-radius: 100%;
          "
        />
        {{ project.country }}</span
      >
    </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["project"],
  computed: {
    ...mapGetters(["countries"]),
    countryFlag() {
      let flag = "";
      if (this.project && this.project.country) {
        console.log(this.countries);
        let newFlag = this.countries.find(
          (flag) =>
            flag.name.toLowerCase() == this.project.country.toLowerCase()
        );

        if (newFlag) {
          flag = newFlag.flag;
        }
      }

      return flag;
    },
  },
};
</script>

<style></style>
