<template>
  <div class="about-banner">
    <h4>We Are The <span class="text-green">Relsified</span></h4>
    <img src="@/assets/img/about-people.png" alt="" />
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.about-banner {
  padding: 80px 180px;
  background: #fff;
  text-align: center;
}

.about-banner h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 61px;
  color: #000000;
  margin-bottom: 80px;
}

.about-banner img {
  width: 100%;
  height: auto;
  border-radius: 30px;
  border: 15px solid rgba(24, 133, 111, 0.295);
}

@media screen and (max-width: 1150px) {
  .about-banner {
    padding: 80px 100px;
  }

  .about-banner h4 {
    font-size: 40px;
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 900px) {
  .about-banner {
    padding: 60px;
  }

  .about-banner h4 {
    font-size: 35px;
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 620px) {
  .about-banner {
    padding: 40px 25px;
  }

  .about-banner h4 {
    font-size: 33px;
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 480px) {
  .about-banner {
    padding: 30px 20px;
  }
  .about-banner h4 {
    font-size: 28px;
    margin-bottom: 30px;
  }
}
</style>
