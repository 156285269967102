<template>
  <div class="green_banner">
    <div class="green_banner_wrapper">
      <div class="green_banner_wrapper_content">
        <h4>We Believe Everyone Deserve to Own a Property</h4>
        <p>
          Leveraging blockchain, smart contract, and tokenization, we securitize
          profitable real estate properties to provide opportunities for
          fractional investments and a very low entry barrier. We bring
          efficiency, security, liquidity, transparency, low cost, speed, and
          global access to the real estate market in Africa.
        </p>
      </div>
      <img src="@/assets/img/vectors/r1.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.green_banner {
  width: 100%;
  background: var(--mainGreenColor);
  color: #fff;
  margin: 40px 0px;
}

.green_banner_wrapper {
  display: flex;
  justify-content: space-between;
  padding: 50px 150px;
}

.green_banner_wrapper .green_banner_wrapper_content {
  width: 650px;
}

.green_banner_wrapper .green_banner_wrapper_content h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 71px;
  margin-bottom: 40px;
}

.green_banner_wrapper .green_banner_wrapper_content p {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 28px;
}

.green_banner_wrapper img {
  width: 280px;
}

@media screen and (max-width: 1350px) {
  .green_banner_wrapper {
    padding: 50px 90px;
  }

  .green_banner_wrapper .green_banner_wrapper_content h4 {
    font-size: 46px;
  }
}

@media screen and (max-width: 1150px) {
  .green_banner_wrapper {
    padding: 50px 50px;
  }

  .green_banner_wrapper .green_banner_wrapper_content {
    width: 590px;
  }

  .green_banner_wrapper .green_banner_wrapper_content h4 {
    font-size: 40px;
    line-height: 61px;
  }
  .green_banner_wrapper img {
    width: 240px;
  }
}

@media screen and (max-width: 1000px) {
  .green_banner_wrapper {
    padding: 50px 30px;
  }

  .green_banner_wrapper .green_banner_wrapper_content {
    width: 500px;
  }

  .green_banner_wrapper .green_banner_wrapper_content h4 {
    font-size: 40px;
    line-height: 51px;
  }
  .green_banner_wrapper img {
    width: 240px;
  }
}

@media screen and (max-width: 850px) {
  .green_banner_wrapper .green_banner_wrapper_content {
    width: 460px;
  }

  .green_banner_wrapper .green_banner_wrapper_content h4 {
    font-size: 30px;
    line-height: 51px;
  }
  .green_banner_wrapper img {
    width: auto;
    height: 240px;
  }
}
@media screen and (max-width: 750px) {
  .green_banner_wrapper .green_banner_wrapper_content {
    width: 100%;
    text-align: center;
  }

  .green_banner_wrapper .green_banner_wrapper_content h4 {
    font-size: 30px;
    line-height: 51px;
  }

  .green_banner_wrapper .green_banner_wrapper_content p {
    font-size: 15px;
  }

  .green_banner_wrapper img {
    display: none;
  }
}
@media screen and (max-width: 520px) {
  .green_banner_wrapper {
    padding: 50px 15px;
  }
  .green_banner_wrapper .green_banner_wrapper_content h4 {
    line-height: 41px;
  }
}
</style>
