<template>
  <div class="founderQuote">
    <img src="@/assets/img/teams/andy-1.png" alt="" />
    <div class="founderQuoteContent">
      <span class="quote_tag oneT">“</span>
      <span class="quote_text">
        We’re a bunch of misfits who believe that anyone can own a piece
        property. Together, we have created a really interesting place to work.
        From our lollipop
      </span>
      <span class="quote_tag twoT">“</span>

      <div class="founderName">
        <div class="founderQuoteName">
          <h5>Andy Umana</h5>
          <p>Founnder, Relsify</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.founderQuote {
  width: 100%;
  display: grid;
  grid-template-columns: 400px 1fr;
  background: var(--mainLightGreenColor2);
  margin-bottom: 40px;
}

.founderQuoteContent {
  padding: 40px 20px 40px 200px;
  width: 810px;
}

.quote_tag {
  font-style: italic;
  font-weight: 500;
  font-size: 129px;
  /* line-height: 93px; */
  color: var(--mainGreenColor);
}

.quote_tag.twoT {
  text-align: right;
  display: flex;
  justify-content: flex-end;
  margin-top: -95px;
}

.quote_text {
  font-style: italic;
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  color: #323232;
  display: inline-block;
  margin-top: -80px;
  margin-left: 40px;
}

.founderQuoteName {
  display: inline-block;
  padding: 16px 80px;
  background: var(--mainGreenColor);
  color: #fff;
  border-radius: 15px;
  margin-left: 40px;
}

.founderQuoteName h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 45px;
  margin-bottom: 0px;
}

.founderQuoteName p {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 31px;
  margin-bottom: 0px;
}

@media screen and (max-width: 1350px) {
  .founderQuoteContent {
    padding: 40px 20px 40px 90px;
    width: 710px;
  }
}

@media screen and (max-width: 1250px) {
  .founderQuote {
    grid-template-columns: 330px 1fr;
  }

  .founderQuote img {
    height: 100% !important;
  }

  .founderQuoteContent {
    padding: 40px 20px 40px 90px;
    width: 710px;
  }

  .quote_tag {
    font-size: 100px;
  }

  .quote_tag.twoT {
    margin-top: -70px;
  }

  .quote_text {
    font-size: 23px;
    line-height: 38px;
    margin-top: -60px;
    margin-left: 20px;
  }

  .founderQuoteName {
    padding: 16px 60px;
    margin-left: 40px;
  }

  .founderQuoteName h5 {
    font-size: 19px;
    line-height: 25px;
  }

  .founderQuoteName p {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 31px;
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 1070px) {
  .founderQuote {
    grid-template-columns: 280px 1fr;
  }

  .founderQuoteContent {
    padding: 40px 50px 40px 40px;
    width: auto;
  }

  .quote_text {
    font-size: 20px;
    line-height: 28px;
    margin-top: -60px;
    margin-left: 20px;
  }

  .quote_tag {
    font-size: 70px;
  }

  .quote_tag.twoT {
    margin-top: -50px;
  }

  .founderQuoteName {
    padding: 16px 60px;
    margin-left: 20px;
  }

  .founderQuoteName h5 {
    font-size: 17px;
    line-height: 20px;
  }

  .founderQuoteName p {
    font-size: 15px;
    line-height: 20px;
  }
}

@media screen and (max-width: 870px) {
  .founderQuote {
    grid-template-columns: 220px 1fr;
  }
}

@media screen and (max-width: 768px) {
  .founderQuote {
    display: block;
    width: 60%;
    margin: 10px auto 40px;
  }

  .founderQuote img {
    height: 420px !important;
    object-fit: contain;
  }
}

@media screen and (max-width: 650px) {
  .founderQuote {
    display: block;
    width: 100%;
    margin: 0px auto 40px;
  }

  .founderQuoteContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .founderQuote img {
    height: 520px !important;
    object-fit: cover;
  }

  .founderQuoteContent {
    padding: 40px 20px 40px;
    width: auto;
  }

  .quote_text {
    font-size: 19px;
    line-height: 22px;
    margin-top: -40px;
  }

  .founderName {
    text-align: center;
  }

  .founderQuoteName {
    display: inline-block;
    margin-left: 0px !important;
  }
}

@media screen and (max-width: 450px) {
  .founderQuote img {
    height: 420px !important;
    object-fit: cover;
  }
}
</style>
