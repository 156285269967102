<template>
  <div>
    <ProductDetails />
    <ProductContainer />
  </div>
</template>

<script>
import ProductDetails from "../components/extras/products/ProductDetails.vue";
import { mapActions } from "vuex";
export default {
  components: {
    ProductDetails,
  },

  data() {
    return {};
  },

  methods: {
    ...mapActions(["setCurrentPageName"]),
  },

  mounted() {
    this.setCurrentPageName(this.$router.currentRoute._rawValue.name);
  },
};
</script>
