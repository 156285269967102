<template>
  <div>
    <TokenizePropertyBanner :bannerType="'FORM'" />
    <TokenizeJourneyForm />
  </div>
</template>

<script>
import TokenizePropertyBanner from "../components/extras/banners/TokenizePropertyBanner.vue";
import TokenizeJourneyForm from "../components/extras/TokenizeJourneyForm.vue";
import { mapActions } from "vuex";
export default {
  components: {
    TokenizePropertyBanner,
    TokenizeJourneyForm,
  },

  methods: {
    ...mapActions(["setCurrentPageName"]),
  },

  mounted() {
    this.setCurrentPageName(this.$router.currentRoute._rawValue.name);
  },
};
</script>
