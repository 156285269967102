<template>
  <div class="banner">
    <div class="wrapper">
      <div class="wrapper-right">
        <h4>Contact <span class="text-yellow"> Us</span></h4>
        <p>
          We create asset-backed <b>security tokens</b> to help security issuers
          raise more capital and offer investors true liquidity.
        </p>
      </div>
      <div class="banner_image">
        <img src="@/assets/img/cheerful-woman.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
/* banner */
.banner {
  background-image: linear-gradient(to right, #ffffffe0, #ffffffec, #ffffffe0),
    url("../../../assets/img/contact-bg.png");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  width: 100%;
  max-height: 500px;
  overflow: hidden;
  padding-top: 0px;
  overflow: hidden;
}

.banner .wrapper {
  padding: 30px 50px 0px 100px;
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr 790px;
}

/* .banner_image {
  display: flex;
  justify-content: flex-end;
  align-items: center;
} */

.banner .wrapper img {
  width: 100%;
  align-self: flex-end;
  margin-top: -50px;
  /* height: auto; */
}

.banner .wrapper-right {
  width: 100%;
  margin: 100px auto 0px;
  display: flex;
  flex-direction: column;
  height: 100%;
  color: rgb(48, 46, 46);
  text-align: left;
}

.banner .wrapper-right h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 54px;
  line-height: 81px;
  margin-bottom: 35px;
  color: #181818;
}

.banner .wrapper-right p {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 140.62%;
}

@media screen and (max-width: 1400px) {
  .banner .wrapper {
    padding: 30px 50px 0px 120px;
    gap: 10px;
    grid-template-columns: 1fr 690px;
  }
}

@media screen and (max-width: 1200px) {
  .banner .wrapper {
    padding: 30px 50px 0px 100px;
    gap: 10px;
    grid-template-columns: 1fr 600px;
  }

  .banner .wrapper img {
    width: 100%;
  }

  .banner .wrapper-right {
    margin: 50px auto 0px;
  }

  .banner .wrapper-right h4 {
    font-size: 49px;
    line-height: 81px;
    margin-bottom: 35px;
  }

  .banner .wrapper-right p {
    font-size: 18px;
    line-height: 140.62%;
  }
}

@media screen and (max-width: 1050px) {
  .banner .wrapper {
    padding: 30px 50px 0px;
    gap: 0px;
    grid-template-columns: 1fr 550px;
  }
}

@media screen and (max-width: 950px) {
  .banner .wrapper {
    padding: 30px 50px 0px;
    grid-template-columns: 1fr 450px;
  }

  .banner .wrapper-right {
    margin: 20px auto 0px;
  }

  .banner .wrapper-right h4 {
    font-size: 37px;
    line-height: 81px;
    margin-bottom: 20px;
  }

  .banner .wrapper-right p {
    font-size: 16px;
  }
}

@media screen and (max-width: 830px) {
  .banner .wrapper {
    padding: 30px 50px 30px;
    grid-template-columns: 1fr 380px;
  }

  .banner .wrapper-right h4 {
    font-size: 29px;
    line-height: 51px;
    margin-bottom: 10px;
  }

  .banner .wrapper-right p {
    font-size: 16px;
  }
}

@media screen and (max-width: 730px) {
  .banner .wrapper {
    padding: 60px 30px 0px;
    grid-template-columns: 1fr 320px;
  }

  .banner .wrapper-right {
    margin: 0px auto 0px;
  }
}
@media screen and (max-width: 600px) {
  .banner .wrapper {
    padding: 30px 30px 0px;
    grid-template-columns: 1fr 1fr;
  }

  .banner .wrapper img {
    margin-top: -20px;
    /* height: auto; */
  }

  .banner .wrapper-right h4 {
    font-size: 19px;
    line-height: 51px;
    margin-bottom: 10px;
  }

  .banner .wrapper-right p {
    font-size: 14px;
  }
}

@media screen and (max-width: 580px) {
  .banner {
    height: auto;
    position: relative;
  }
  .banner .wrapper {
    padding: 30px 20px 0px;
    display: block;
    width: 80%;
    padding: 0px;
    margin: 10px auto;
    margin-top: 0px;
    position: relative;
    text-align: center;
  }

  .banner .wrapper img {
    display: none;
  }

  .banner .wrapper-right {
    margin: 50px 0px;
    width: 100%;
    display: block;
    height: auto;
  }

  .banner .wrapper-right h4 {
    font-size: 27px;
    line-height: 51px;
    margin-bottom: 10px;
    text-align: center;
  }

  .banner .wrapper-right p {
    font-size: 16px;
    text-align: center;
  }
}
</style>
