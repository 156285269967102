<template>
  <div class="terms-template-1">
    <div class="terms-template-1-top">
      <!-- <h4>Relsify Terms & Conditions</h4> -->
      <h4>{{ title1 }}</h4>
    </div>
    <div class="terms-template-1-content">
      <div class="terms-template-1-content-top">
        <h5>{{ title2 }}</h5>
        <div class="terms-template-1-content-top-links">
          <a href="#Age_Restriction" class="active">
            Age Restriction <span>></span>
          </a>
          <a href="#Intellectual_Property">
            Intellectual Property <span>></span>
          </a>
          <a href="#licennce_to_use">
            licennce to use website <span>></span>
          </a>
          <a href="#who_may_use"> Who May Use Website <span>></span> </a>
          <a href="#Indemnification"> Indemnification <span>></span> </a>
        </div>
      </div>
      <div class="terms-template-1-content-body">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vel,
          suspendisse risus eget dolor ultrices quisque donec ut habitant. Quis
          amet vestibulum viverra ipsum, egestas odio porttitor felis. Sit felis
          elementum eget feugiat sem neque at tempus. Maecenas tincidunt vitae
          ultricies at convallis. Nunc, neque, ultricies non non pulvinar
          ornare. Nunc est in sodales enim faucibus vestibulum platea est
          potenti. Netus leo pulvinar scelerisque at amet cursus aliquet
          viverra. Placerat eget aliquet pharetra varius.
        </p>
        <div id="Age_Restriction">
          <h6>Age Restriction</h6>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vel,
            suspendisse risus eget dolor ultrices quisque donec ut habitant.
            Quis amet vestibulum viverra ipsum, egestas odio porttitor felis.
            Sit felis elementum eget feugiat sem neque at tempus. Maecenas
            tincidunt vitae ultricies at convallis. Nunc, neque, ultricies non
            non pulvinar ornare. Nunc est in sodales enim faucibus vestibulum
            platea est potenti. Netus leo pulvinar scelerisque at amet cursus
            aliquet viverra. Placerat eget aliquet pharetra varius.
          </p>
        </div>

        <div id="Intellectual_Property">
          <h6>Intellectual Property</h6>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vel,
            suspendisse risus eget dolor ultrices quisque donec ut habitant.
            Quis amet vestibulum viverra ipsum, egestas odio porttitor felis.
            Sit felis elementum eget feugiat sem neque at tempus. Maecenas
            tincidunt vitae ultricies at convallis. Nunc, neque, ultricies non
            non pulvinar ornare. Nunc est in sodales enim faucibus vestibulum
            platea est potenti. Netus leo pulvinar scelerisque at amet cursus
            aliquet viverra. Placerat eget aliquet pharetra varius.
          </p>
        </div>

        <div id="licennce_to_use">
          <h6>licennce to use website</h6>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vel,
            suspendisse risus eget dolor ultrices quisque donec ut habitant.
            Quis amet vestibulum viverra ipsum, egestas odio porttitor felis.
            Sit felis elementum eget feugiat sem neque at tempus. Maecenas
            tincidunt vitae ultricies at convallis. Nunc, neque, ultricies non
            non pulvinar ornare. Nunc est in sodales enim faucibus vestibulum
            platea est potenti. Netus leo pulvinar scelerisque at amet cursus
            aliquet viverra. Placerat eget aliquet pharetra varius.
          </p>
        </div>

        <div id="who_may_use">
          <h6>Who May Use Website</h6>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vel,
            suspendisse risus eget dolor ultrices quisque donec ut habitant.
            Quis amet vestibulum viverra ipsum, egestas odio porttitor felis.
            Sit felis elementum eget feugiat sem neque at tempus. Maecenas
            tincidunt vitae ultricies at convallis. Nunc, neque, ultricies non
            non pulvinar ornare. Nunc est in sodales enim faucibus vestibulum
            platea est potenti. Netus leo pulvinar scelerisque at amet cursus
            aliquet viverra. Placerat eget aliquet pharetra varius.
          </p>
        </div>

        <div id="Indemnification">
          <h6>Who May Use Website</h6>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vel,
            suspendisse risus eget dolor ultrices quisque donec ut habitant.
            Quis amet vestibulum viverra ipsum, egestas odio porttitor felis.
            Sit felis elementum eget feugiat sem neque at tempus. Maecenas
            tincidunt vitae ultricies at convallis. Nunc, neque, ultricies non
            non pulvinar ornare. Nunc est in sodales enim faucibus vestibulum
            platea est potenti. Netus leo pulvinar scelerisque at amet cursus
            aliquet viverra. Placerat eget aliquet pharetra varius.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title1", "title2"],
};
</script>

<style scoped>
.terms-template-1 {
  width: 100%;
}

.terms-template-1-top {
  background: var(--mainLightGreenColor);
  width: 100%;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;
  font-family: var(--mainFontFamily);
}

.terms-template-1-top h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 81px;
  color: #181818;
}

.terms-template-1-content {
  margin-top: 40px;
  padding: 0px 150px 100px;
}

.terms-template-1-content-top {
  text-align: center;
  margin-bottom: 40px;
}

.terms-template-1-content-top h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 52px;
  color: #181818;
}

.terms-template-1-content-top-links {
  margin-top: 10px;
}

.terms-template-1-content-top-links a {
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;
  color: #606060;
  margin: 0px 10px;
}

.terms-template-1-content-top-links a:hover {
  color: var(--mainGreenColor);
}

.terms-template-1-content-top-links a.active {
  color: #000000;
  font-weight: bold;
}

.terms-template-1-content-body {
  margin-top: 70px;
}

.terms-template-1-content-body p {
  font-family: var(--mainFontFamily);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140.62%;
  color: #363636;
}

.terms-template-1-content-body h6 {
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 52px;
  color: #181818;
}

@media screen and (max-width: 1200px) {
  .terms-template-1-top {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .terms-template-1-top h4 {
    font-size: 45px;
  }

  .terms-template-1-content-top h5 {
    font-size: 30px;
  }
}

@media screen and (max-width: 950px) {
  .terms-template-1-top {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .terms-template-1-content {
    padding: 0px 60px 80px;
  }

  .terms-template-1-top h4 {
    font-size: 38px;
  }

  .terms-template-1-content-top h5 {
    font-size: 27px;
  }
}
@media screen and (max-width: 750px) {
  .terms-template-1-top {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .terms-template-1-content {
    padding: 0px 25px 60px;
  }

  .terms-template-1-top h4 {
    font-size: 30px;
  }

  .terms-template-1-content-top h5 {
    font-size: 24px;
  }
}
@media screen and (max-width: 550px) {
  .terms-template-1-top {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .terms-template-1-content {
    padding: 0px 20px 60px;
  }

  .terms-template-1-top h4 {
    font-size: 28px;
  }

  .terms-template-1-content-top h5 {
    font-size: 21px;
  }

  .terms-template-1-content-body p {
    font-size: 15px;
  }

  .terms-template-1-content-body h6 {
    font-size: 17px;
  }
}
</style>
