<template>
  <div class="amazing_team">
    <h4>Our Amazing<span class="text-yellow"> Team</span></h4>
    <div class="amazing_team_grid">
      <div class="amazing_team_grid_item">
        <img src="@/assets/img/teams/andy.png" alt="" />
        <div class="amazing_team_grid_item_content">
          <h5>Andy <span class="text-green">Umana</span></h5>
          <p>Founder</p>
          <a href="" class="amazing_team_grid_item_content_social">
            <img src="@/assets/img/vectors/linkedin.png" alt="" />
          </a>
        </div>
      </div>
      <div class="amazing_team_grid_item">
        <img src="@/assets/img/teams/ifedayo.png" alt="" />
        <div class="amazing_team_grid_item_content">
          <h5>Ifedayo <span class="text-green">Karim</span></h5>
          <p>Technical Lead</p>
          <a href="" class="amazing_team_grid_item_content_social">
            <img src="@/assets/img/vectors/linkedin.png" alt="" />
          </a>
        </div>
      </div>
      <div class="amazing_team_grid_item">
        <img src="@/assets/img/teams/fadilah.png" alt="" />
        <div class="amazing_team_grid_item_content">
          <h5>Fadilah <span class="text-green">Olaoye</span></h5>
          <p>Project Lead</p>
          <a href="" class="amazing_team_grid_item_content_social">
            <img src="@/assets/img/vectors/linkedin.png" alt="" />
          </a>
        </div>
      </div>
      <div class="amazing_team_grid_item">
        <img src="@/assets/img/teams/olatoye.png" alt="" />
        <div class="amazing_team_grid_item_content">
          <h5>Olatoye <span class="text-green">Sulaimon</span></h5>
          <p>Operations Lead</p>
          <a href="" class="amazing_team_grid_item_content_social">
            <img src="@/assets/img/vectors/linkedin.png" alt="" />
          </a>
        </div>
      </div>
      <div class="amazing_team_grid_item">
        <img src="@/assets/img/teams/timi.png" alt="" />
        <div class="amazing_team_grid_item_content">
          <h5>Timi-Odeyemi <span class="text-green">Samuel</span></h5>
          <p>Content Manager</p>
          <a href="" class="amazing_team_grid_item_content_social">
            <img src="@/assets/img/vectors/linkedin.png" alt="" />
          </a>
        </div>
      </div>
      <div class="amazing_team_grid_item">
        <img src="@/assets/img/teams/ayomide.png" alt="" />
        <div class="amazing_team_grid_item_content">
          <h5>Ayomide <span class="text-green">Olupitan</span></h5>
          <p>Product Manager</p>
          <a href="" class="amazing_team_grid_item_content_social">
            <img src="@/assets/img/vectors/linkedin.png" alt="" />
          </a>
        </div>
      </div>
      <div class="amazing_team_grid_item">
        <img src="@/assets/img/teams/george.png" alt="" />
        <div class="amazing_team_grid_item_content">
          <h5>George <span class="text-green">Etokakpan</span></h5>
          <p>Head of Legal</p>
          <a href="" class="amazing_team_grid_item_content_social">
            <img src="@/assets/img/vectors/linkedin.png" alt="" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.amazing_team {
  width: 100%;
  background: #fff;
  padding: 100px 90px;
}

.amazing_team h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 67px;
  color: #000000;
  margin-bottom: 90px;
}

.amazing_team_grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;
}

.amazing_team_grid_item {
  box-shadow: 0px 3.43343px 10.3003px 3.43343px rgba(75, 75, 75, 0.25);
  border-bottom-left-radius: 12.8754px;
  border-bottom-right-radius: 12.8754px;
  border-top-left-radius: 12.8754px;
  border-top-right-radius: 12.8754px;
}

.amazing_team_grid_item img {
  width: 100%;
  height: auto;
  border-top-left-radius: 12.8754px;
  border-top-right-radius: 12.8754px;
  filter: grayscale(100%) contrast(100%) brightness(100%);
}

.amazing_team_grid_item_content {
  padding: 20px 20px;
}

.amazing_team_grid_item_content h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 24px;
  color: #000000;
}

.amazing_team_grid_item_content p {
  font-style: normal;
  font-weight: normal;
  font-size: 15.4504px;
  line-height: 19px;
}

.amazing_team_grid_item_content_social {
  display: flex;
  justify-content: flex-end;
  margin-top: -40px;
}
.amazing_team_grid_item_content img {
  width: 30px;
  filter: none;
}

@media screen and (max-width: 1260px) {
  .amazing_team {
    padding: 70px 60px;
  }

  .amazing_team h4 {
    font-size: 40px;
    line-height: 67px;
    margin-bottom: 60px;
  }

  .amazing_team_grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
  }
}

@media screen and (max-width: 960px) {
  .amazing_team {
    padding: 70px 30px;
  }

  .amazing_team h4 {
    font-size: 35px;
    line-height: 47px;
    margin-bottom: 50px;
  }

  .amazing_team_grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 40px 20px;
  }

  .amazing_team_grid_item_content {
    padding: 20px 20px;
  }

  .amazing_team_grid_item_content h5 {
    font-size: 17px;
    line-height: 20px;
  }

  .amazing_team_grid_item_content p {
    font-size: 14px;
    line-height: 18px;
  }
}

@media screen and (max-width: 770px) {
  .amazing_team {
    padding: 70px 30px;
  }

  .amazing_team h4 {
    font-size: 32px;
    line-height: 40px;
  }

  .amazing_team_grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px 20px;
  }

  .amazing_team_grid_item_content {
    padding: 20px;
  }

  .amazing_team_grid_item_content h5 {
    font-size: 17px;
    line-height: 20px;
  }

  .amazing_team_grid_item_content p {
    font-size: 14px;
    line-height: 18px;
  }
}

@media screen and (max-width: 600px) {
  .amazing_team {
    padding: 70px 20px;
  }

  .amazing_team h4 {
    font-size: 30px;
    line-height: 40px;
    text-align: center;
  }

  .amazing_team_grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px 20px;
  }

  .amazing_team_grid_item_content {
    padding: 20px;
  }

  .amazing_team_grid_item_content h5 {
    font-size: 15px;
  }

  .amazing_team_grid_item_content p {
    font-size: 14px;
  }
}

@media screen and (max-width: 450px) {
  .amazing_team {
    padding: 70px 40px;
  }
  .amazing_team h4 {
    font-size: 25px;
  }
  .amazing_team_grid {
    display: block;
  }

  .amazing_team_grid_item {
    margin-bottom: 30px;
  }
}
</style>
