<template>
  <div class="ourStory">
    <div class="ourStory_wrapper">
      <div class="ourStory_wrapper_content">
        <h4>Our <span class="text-yellow">Story</span></h4>
        <p>
          A walk down the memory lane points to who we are, where we see
          ourselves and what we stand for.
        </p>
      </div>
      <div class="ourStory_wrapper_content_right">
        <div class="relsify_tab_4">
          <div class="relsify_tab_4_nav">
            <a
              href="javascript:;"
              tab-id="AWhoWeAre"
              class="relsify_tab_4_nav_link active"
              >Who we are</a
            >
            <a
              href="javascript:;"
              tab-id="AOurVision"
              class="relsify_tab_4_nav_link"
              >Our vision</a
            >
            <a
              href="javascript:;"
              tab-id="AOurValues"
              class="relsify_tab_4_nav_link"
              >Our Values</a
            >
          </div>
        </div>
        <div class="relsify_tab_4_content">
          <div class="relsify_tab_4_content_pan active" id="AWhoWeAre">
            <p>
              Relsify is Africa's first tokenization platform leveraging
              blockchain to revolutionize Africa's real estate market with
              liquidity by lowering the entry barrier of investment and
              increasing the funding capacity of developers to a global level.
            </p>
          </div>
          <div class="relsify_tab_4_content_pan" id="AOurVision">
            <p>
              To be known as Africa's biggest tokenization platform leveraging
              blockchain to provide irresistible investing experience, unlock
              untapped potentials, while also solving decades of real-time
              problems for the benefit of Africans.
            </p>
          </div>
          <div class="relsify_tab_4_content_pan" id="AOurValues">
            <p>
              At Relsify, we value:
              <br />
              • Blockchain Technology, Smart Contracts & Tokenization <br />
              • Efficiency driven by cutting-edge innovation <br />
              • Integrity, optimism, and strong belief within our team &
              community • Equal opportunity for all to tap into earth's
              resources
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.ourStory {
  width: 100%;
  background: #fff;
  color: #222;
  margin: 40px 0px 0px;
}

.ourStory_wrapper {
  display: grid;
  grid-template-columns: 580px 1fr;
  padding: 190px 110px;
}

.ourStory_wrapper .ourStory_wrapper_content h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 54px;
  line-height: 71px;
  margin-bottom: 20px;
  color: #181818;
}

.ourStory_wrapper .ourStory_wrapper_content p {
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 31px;
  color: #000000;
  width: 79%;
}

@media screen and (max-width: 1350px) {
  .ourStory_wrapper {
    padding: 120px 90px;
  }

  .ourStory_wrapper .ourStory_wrapper_content h4 {
    font-size: 46px;
  }
}

@media screen and (max-width: 1220px) {
  .ourStory_wrapper {
    padding: 120px 60px;
    grid-template-columns: 400px 1fr;
  }

  .ourStory_wrapper .ourStory_wrapper_content h4 {
    font-size: 46px;
  }
}

@media screen and (max-width: 1150px) {
  .ourStory_wrapper {
    padding: 110px 50px;
  }

  .ourStory_wrapper .ourStory_wrapper_content h4 {
    font-size: 40px;
    line-height: 61px;
  }
}

@media screen and (max-width: 1050px) {
  .ourStory_wrapper {
    padding: 90px 50px;
    display: block;
  }

  .ourStory_wrapper .ourStory_wrapper_content {
    text-align: center;
    width: 100%;
    margin-bottom: 70px;
  }

  .ourStory_wrapper .ourStory_wrapper_content h4 {
    font-size: 40px;
    line-height: 51px;
  }

  .ourStory_wrapper .ourStory_wrapper_content p {
    width: 100%;
  }
}

@media screen and (max-width: 850px) {
  .ourStory_wrapper .ourStory_wrapper_content h4 {
    font-size: 30px;
    line-height: 51px;
  }
}
@media screen and (max-width: 750px) {
  .ourStory_wrapper {
    padding: 90px 20px;
  }

  .ourStory_wrapper .ourStory_wrapper_content h4 {
    font-size: 35px;
    line-height: 51px;
  }

  .ourStory_wrapper .ourStory_wrapper_content p {
    font-size: 15px;
  }
}
@media screen and (max-width: 550px) {
  .ourStory_wrapper {
    padding: 40px 20px;
  }
  .ourStory_wrapper .ourStory_wrapper_content {
    margin-bottom: 30px;
  }
}
</style>
