<template>
  <div>
    <AboutBanner />
    <GreenBanner />
    <OurStory />
    <FounderQuote />
    <AmazingTeam />
    <JoinTheTeam />
  </div>
</template>

<script>
import AboutBanner from "../components/extras/banners/AboutBanner.vue";
import GreenBanner from "../components/extras/banners/GreenBanner.vue";
import OurStory from "../components/extras/OurStory.vue";
import FounderQuote from "../components/extras/FounderQuote.vue";
import AmazingTeam from "../components/extras/AmazingTeam.vue";
import JoinTheTeam from "../components/extras/JoinTheTeam.vue";
import { mapActions } from "vuex";
export default {
  components: {
    AboutBanner,
    GreenBanner,
    OurStory,
    FounderQuote,
    AmazingTeam,
    JoinTheTeam,
  },

  data() {
    return {
      title1: "Relsify Privacy Policy",
      title2: "Global Privacy Policy",
    };
  },
  methods: {
    ...mapActions(["setCurrentPageName"]),
  },

  mounted() {
    this.setCurrentPageName(this.$router.currentRoute._rawValue.name);
  },
};
</script>
